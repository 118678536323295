import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import background from "../../../../assets/images/section2.jpg";
import { AbsoluiteImage, CustomGrid, SectionHeading, VideoBg, VideoBgmobile } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import sectionScrollButton from "../../../../assets/images/sectionScrollButton.png";

import backgroundmobile from "../../../../assets/images/DogDetective.jpg";
import Section2bgw from "../../../../assets/videos/Section2bgw.mp4";
import Section2bgm from "../../../../assets/videos/Section2bgm.mp4";

const ContinerWraper = styled(Container)`
  min-height: 100dvh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    background-image: url(${backgroundmobile});

  }
`;
const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    min-height: auto;
  
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl">
      <VideoBg autoPlay loop muted>
          <source src={Section2bgw} />
        </VideoBg>
        <VideoBgmobile autoPlay loop muted>
          <source src={Section2bgm} />
        </VideoBgmobile>
        <InnerContinerWraper>
          <CustomGrid container p="50px 0" ai="start" jc="start">
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={7}
              ai="start"
              fd="column"
              g="20px"
            >
              <CustomGrid container item xs={12} ai="start" jc="start">
                <SectionHeading>
                  Try our Woof cards. <br /> Pay anonymously, <br /> without a whimper!
                </SectionHeading>
              </CustomGrid>
              <CustomGrid container item xs={12} sm={9} ai="start" jc="start">
                <Paragraph
                  sx={{ fontSize: { xs: "18px", sm: "22px" },zIndex:"1"}}
                  padding="10px 0"
                >
                  Remember Fiat? Yeah, neither do we. Embrace the future with
                  our virtual and physical crypto debit cards. Spend money
                  anonymously, just like that time you bought too many Durex
                  products. Never ended up using them for doggy did you huh?
                </Paragraph>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
          <Link href="#Cards">
              <AbsoluiteImage  src={sectionScrollButton} />
            </Link>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
