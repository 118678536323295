import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import background from "../../../../assets/images/section3.png";
import {
  AbsoluiteImage,
  CustomGrid,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import sectionScrollButton from "../../../../assets/images/sectionScrollButton.png";
import debitcard from "../../../../assets/images/debitcard.png";

import backgroundmobile from "../../../../assets/images/DogCard.jpg";

const ContinerWraper = styled(Container)`
  min-height: 100dvh;
  width: 100%;
  background-color: #b9e4f4;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
  @media (max-width: 599px) {
  }
`;
const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const AbsoluiteCardImage = styled.img`
  width: 50%;
  position: absolute;
  bottom: ${(props) => (props.t ? props.t : "25%")};
  right: ${(props) => (props.l ? props.l : "10%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  transition: 0.2s;
  cursor: pointer;
  z-index: 0;
  /* animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);  */
  animation: bounce 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  @media (max-width: 599px) {
    width: 100%;
    bottom:  25%;
    right: 0;
  }
  @media (min-width: 1440px) {
    width: 40%;
    
  }
`;

const Cards = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl">
        <AbsoluiteCardImage src={debitcard} />
        <InnerContinerWraper>
          <CustomGrid container p="50px 0" ai="start" jc="start">
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={7}
              ai="start"
              fd="column"
              g="20px"
            >
              <CustomGrid container item xs={12} ai="start" jc="start">
                <SectionHeading>
                  How about our Premium Wag-tastic cards?
                </SectionHeading>
              </CustomGrid>
              <CustomGrid container item xs={12} sm={9} ai="start" jc="start">
                <Paragraph
                  sx={{ fontSize: { xs: "18px", sm: "22px" }, zIndex: "1" }}
                  padding="10px 0"
                  color="#000000"
                >
                  Introducing the Dog Wif Card Premium Card: Because being basic
                  is for the other guys. Get ready to elevate your finances with
                  a card that's more elusive than a bitch who's actually into
                  you.
                </Paragraph>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
          <Link href="#Tokenomics">
            <AbsoluiteImage src={sectionScrollButton} />
          </Link>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Cards;
