import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import background from "../../../../assets/images/section4.png";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import sectionScrollButton from "../../../../assets/images/sectionScrollButton.png";
const ContinerWraper = styled(Container)`
  min-height: 100dvh;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, #54b4e6, #99dafb);
  padding: 0 !important;
  position: relative;
  /* @media (max-width: 599px) {
    background-position: right;

  } */
`;
const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  display: flex;
  align-items: center;
`;

const AbsoluiteCardImage = styled.img`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "0")};
  left: ${(props) => (props.l ? props.l : "0")};
  margin-right: ${(props) => (props.m ? props.m : "10%")};
  transition: 0.2s;
  object-fit: contain;
  cursor: pointer;
  animation: movment 10s;
  z-index: 0;
  /* animation-direction: alternate; */
  /* animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);  */
  animation-iteration-count: infinite;
  @media (max-width: 599px) {
    animation: movmentm 7s;
    height: 50vh;
    animation-iteration-count: infinite;
  }
`;
const AbsoluiteCdImage = styled.img`
  width: 100%;
  height: 60vh;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "0")};
  left: ${(props) => (props.l ? props.l : "-50%")};
  margin-right: ${(props) => (props.m ? props.m : "80%")};
  transition: 0.2s;
  object-fit: contain;
  cursor: pointer;
  animation: movment 15s;
  z-index: 0;
  /* animation-direction: alternate; */
  /* animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);  */
  animation-iteration-count: infinite;
  @media (max-width: 599px) {
    animation: movmentm 10s;
    height: 75vh;
    animation-iteration-count: infinite;
  }
`;
const Tokenomics = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl">
        <AbsoluiteCardImage src={background} />
        <AbsoluiteCdImage src={background} />
        <InnerContinerWraper>
          <CustomGrid container p="50px 0">
            <CustomGrid container item xs={12} fd="column" g="20px">
              <CustomGrid container item xs={12}>
                <SectionHeading ta="center">
                  Woofonomics Breakdown
                </SectionHeading>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={9}
                p="30px"
                jc="start"
                sx={{
                  background: "#ffffff36",
                  borderRadius: "30px",
                  backdropFilter: "blur(5px)",
                }}
              >
                <Paragraph
                  sx={{ fontSize: { xs: "16px", sm: "20px" } }}
                  color="#000000a7"
                >
                  - Name: Dog Wif Card <br />
                  - Network: Solana <br />
                  - Symbol: $CARD <br />
                  - Decimal: 18 <br /> <br />
                  - Fee: We look at racks, 1/1 tax. <br />
                  - Distribution that Barks Success; All 1B tokens are dedicated
                  to LP. <br />
                  - Debit Cards: TBA (More than a card – it fetches returns
                  too.)
                  <br />
                </Paragraph>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
          <Link href="#Contact">
            <AbsoluiteImage src={sectionScrollButton} />
          </Link>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Tokenomics;
