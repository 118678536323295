import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

const PageWrapper = styled.div`
  background-color: #000;
  position: relative;
  overflow: hidden;
  /* overflow: hidden !important; */
`;
const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.t ? props.t : "20px")};
  right: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(50%,0%)")};
  max-width: ${(props) => (props.mw ? props.mw : "50px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  transition: 0.2s;
  filter: drop-shadow(0px 0px 2px #000);
  cursor: pointer;
  :hover {
    /* transform: scale(1.2);
    right: ${(props) => (props.l ? props.l : "48%")}; */
    max-width: 60px;
    bottom: 25px;
    filter: drop-shadow(0px 0px 4px #000);
  }
`;
const VideoBg = styled.video`
  width: 100%;
  height: 100dvh;
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  background-color: #fff;
  display: block;
  @media (max-width: 599px) {
    display: none;
  }
`;
const VideoBgmobile = styled.video`
  width: 100%;
  height: 100dvh;
  position: absolute;
  bottom: 0;
  right: 0;
  object-fit: cover;
  background-color: #fff;
  display: none;
  @media (max-width: 599px) {
    display: block;
  }
`;
const SectionHeading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "left")};
  font-size: ${(props) => (props.fs ? props.fs : "40px")};
  font-family: ${(props) => (props.fm ? props.fm : "comicSansBold")};
  text-shadow: 2px 2px 2px #000;
  /* line-height: 1; */
  position: relative;
  text-transform: uppercase;
  z-index: 9;
  color: ${(props) => (props.c ? props.c : "#F6BA00")};
  @media (max-width: 1175px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1175 : "40px")};
  }
  @media (max-width: 1024px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1024 : "30px")};
  }
  @media (max-width: 599px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "24px")};
  }
`;

const CustomGrid = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  @media (max-width: 599px) {
    flex-direction: ${(props) => props.fd599};
  }
`;
const ColumnGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
`;

export {
  PageWrapper,
  DataImage,
  AbsoluiteImage,
  SectionHeading,
  CustomGrid,
  ColumnGrid,
  VideoBg,
  VideoBgmobile,
};
