import React, { useState } from "react";
import { Box, Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import {
  AbsoluiteImage,
  CustomGrid,
  SectionHeading,
  VideoBg,
  VideoBgmobile,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import background from "../../../../assets/images/section1.jpg";
import backgroundmobile from "../../../../assets/images/DogPig.jpg";
import Section1bgw from "../../../../assets/videos/Section1bgw.mp4";
import Section1bgm from "../../../../assets/videos/Section1bgm.mp4";
import tg from "../../../../assets/images/tg.png";
import x from "../../../../assets/images/x.png";
import copy from "../../../../assets/images/copy.png";

import sectionScrollButton from "../../../../assets/images/sectionScrollButton.png";
const ContinerWraper = styled(Container)`
  min-height: 100dvh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    background-image: url(${backgroundmobile});
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const HeroSection = () => {
    const [textToCopy, setTextToCopy] = useState('0x000000000000000000000000000'); // Set your text here
  
    const handleCopyText = () => {
      // Use the Clipboard API to copy text to the clipboard
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          console.log('Text copied to clipboard:', textToCopy);
          // You can show a success message or perform any other action here after copying
        })
        .catch((error) => {
          console.error('Error copying text:', error);
          // Handle the error if copying fails
        });
    };
  
  return (
    <>
      <ContinerWraper maxWidth="xxl">
        <VideoBg autoPlay loop muted>
          <source src={Section1bgw} />
        </VideoBg>
        <VideoBgmobile autoPlay loop muted>
          <source src={Section1bgm} />
        </VideoBgmobile>

        <InnerContinerWraper>
          <CustomGrid container p="50px 0" ai="start" jc="start">
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={7}
              ai="start"
              fd="column"
              g="20px"
            >
              <CustomGrid container item xs={12} ai="start" jc="start">
                <SectionHeading>
                  Welcome to Dog Wif Card, Where KYC is a Myth!
                </SectionHeading>
              </CustomGrid>
              <CustomGrid container item xs={12} sm={9} ai="start" jc="start" style={{flexDirection:'column'}}>
                <Paragraph
                  sx={{
                    fontSize: { xs: "18px", sm: "22px" },
                    zIndex: "1",
                    color: { xs: "#000 ", sm: "#fff" },
                  }}
                  padding="10px 0"
                >
                  KYC? More like 'Kiss Your Credentials' goodbye! At Dog Wif Card, we believe in anonymity. No KYC hassle, just smooth
                  transactions and a mysterious crypto pawprint. IDs are for
                  cats!
                </Paragraph>
                <Paragraph
                  sx={{
                    fontSize: { xs: "16px", sm: "18px" },
                    zIndex: "1",
                    color: { xs: "#000 ", sm: "#000" },
                    backgroundColor: '#e5be22',
                    border: '1px solid black',
                    padding: '10px 30px 10px 7px',
                    width: '100%',
                    wordBreak: 'break-word',
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                  padding="10px 0"
                  onClick={handleCopyText}
                >
                  CA: {textToCopy}
                  <img src={copy} style={{height: 22, width: 'auto', position: 'absolute', right: 5, top: 5}}/>
                </Paragraph>
                <Box zIndex={1} paddingTop={2}>
                  <Link href="https://t.me/dogwifcard" target="_blank" zIndex={1} paddingRight={2} >
                    <img src={tg} style={{height: 40, width: 'auto'}}/>
                  </Link>
                  <Link href="https://twitter.com/dogwifcard" target="_blank" zIndex={1} >
                    <img src={x} style={{height: 40, width: 'auto'}}/>
                  </Link>
                </Box>
              </CustomGrid>
            </CustomGrid>
            <Link href="#About">
              <AbsoluiteImage src={sectionScrollButton} />
            </Link>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;
