import styled from "@emotion/styled";
import { Container, Link } from "@mui/material";
import React from "react";
import background from "../../../../assets/images/section5.jpg";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
  VideoBg,
  VideoBgmobile,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import sectionScrollButton from "../../../../assets/images/sectionScrollButton.png";

import Section5bgw from "../../../../assets/videos/Section5bgw.mp4";
import Section5bgm from "../../../../assets/videos/Section5bgm.mp4";

import backgroundmobile from "../../../../assets/images/DogNews.png";
const ContinerWraper = styled(Container)`
  min-height: 100dvh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  background-color: #c19a6d;
`;
const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;
const Contact = () => {
  return (
    <ContinerWraper maxWidth="xxl">
      <VideoBg autoPlay loop muted style={{ bottom: "40px" }}>
        <source src={Section5bgw} />
      </VideoBg>
      <VideoBgmobile autoPlay loop muted style={{ bottom: "40px" }}>
        <source src={Section5bgm} />
      </VideoBgmobile>
      <InnerContinerWraper>
        <CustomGrid container p="50px 0" ai="start" jc="start">
          <CustomGrid
            container
            item
            xs={12}
            sm={9}
            md={7}
            ai="start"
            fd="column"
            g="20px"
          >
            <CustomGrid container item xs={12} ai="start" jc="start">
              <SectionHeading>
                Stay Updated: Because <br /> FOMO is Fur-Real
              </SectionHeading>
            </CustomGrid>
            <CustomGrid container item xs={12} sm={9} ai="start" jc="start">
              <Paragraph
                sx={{ fontSize: { xs: "16px", sm: "22px" }, zIndex: "1" }}
                padding="10px 0"
                color="#000"
              >
                Don't let FOMO ruin your crypto game. Stay updated on the latest
                Dog Wif Card news. It's like gossip, but for the financially
                savvy. Join now, and thank us later, or not, whatever. Stay
                ahead of the pack!
              </Paragraph>
            </CustomGrid>
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              ai="start"
              g="20px"
              jc="start"
              zIndex={1}
            >
              <Link href="https://t.me/dogwifcard" target="blank">
                <DataImage mw="40px" src={tg} />
              </Link>
              <Link href="https://twitter.com/dogwifcard" target="blank">
                <DataImage mw="40px" src={x} />
              </Link>
            </CustomGrid>
          </CustomGrid>
        </CustomGrid>
        {/* <Link href="#Home">
          <AbsoluiteImage src={sectionScrollButton} />
        </Link> */}
      </InnerContinerWraper>
    </ContinerWraper>
  );
};

export default Contact;
