import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import { SectionsContainer, Section } from "react-fullpage";
import AboutSection from "../../Components/Sections/S2-About";
import Cards from "../../Components/Sections/S3-Cards";
import Tokenomics from "../../Components/Sections/S4-Tokenomics";
import Contact from "../../Components/Sections/S5-Contact";
const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const options = {
    anchors: ["Home", "About", "Cards", "Tokenomics", "Contact"],
    navigation: true,
    navigationPosition: "left",
    responsiveWidth: 768,
  };
  return (
      <SectionsContainer {...options}>
        <Section>
          <HeroSection />
        </Section>

        <Section>
          <AboutSection />
        </Section>

        <Section>
          <Cards />
        </Section>

        <Section>
          <Tokenomics />
        </Section>

        <Section>
          <Contact />
        </Section>
      </SectionsContainer>
  );
};

export default MainPage;
